<template>
    <div>
        <el-card shadow="never" v-loading="loading">
            <div slot="header" class="title">
                <el-row>
                    <span style="float: left;padding-top: 5px">{{title}}</span>
                    <span style="float: right">
                        <el-button @click="openEdit" size="mini">编辑</el-button>
                        <el-button @click="delCommand" size="mini" type="danger">删除</el-button>
                    </span>
                </el-row>
            </div>
            <el-collapse v-model="activeName">
                <el-collapse-item title="基本信息" name="baseInfo">
                    <el-form label-position="right" label-width="110px" disabled>
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="中文名称：">
                                    <el-input v-model="detail.commandNameCn" class="optItem"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="英文名称：">
                                    <el-input v-model="detail.commandName" class="optItem"/>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="数据入库：">
                                    <el-select v-model="detail.autoSave" class="optItem">
                                        <el-option
                                                v-for="item in yesNoSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row class="lastRow">
                            <el-col :span="8">
                                <el-form-item label="指令类型：">
                                    <el-select v-model="detail.commandType" class="optItem">
                                        <el-option
                                                v-for="item in commandTypeSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="校验方式：">
                                    <el-select v-model="detail.verification" class="optItem">
                                        <el-option
                                                v-for="item in verificationSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="校验码大小端：">
                                    <el-select v-model="detail.verifyEndian" class="optItem">
                                        <el-option
                                                v-for="item in verifyEndianSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="备注：" prop="remark">
                                    <el-input v-model="detail.remark" autocomplete="off" type="textarea" :rows="3"
                                              class="textArea"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="定时信息" name="timerInfo">
                    <el-form label-position="right" label-width="110px" disabled>
                        <el-row class="lastRow">
                            <el-col :span="8">
                                <el-form-item label="定时执行：">
                                    <el-select v-model="detail.enableTimer" class="optItem">
                                        <el-option
                                                v-for="item in yesNoSelect"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="cron表达式：">
                                    <el-input v-model="detail.cron" class="optItem"/>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
                <el-collapse-item title="寄存器信息" name="registerInfo">
                    <el-table :data="detail.registerInfoList" border size="small" stripe
                              :header-cell-style="{background:'#eef1f6',color:'#606266', textAlign: 'center'}">
                        <el-table-column type="index" width="50px" label="序号" align="center"></el-table-column>
                        <el-table-column prop="fieldEn" label="字段键名" align="center"/>
                        <el-table-column prop="fieldCn" label="中文说明" width="130px" align="left"/>
                        <el-table-column prop="rwModel" label="读写模式" width="50px" align="center" :formatter="rwModelFormat"/>
                        <el-table-column prop="address" label="寄存器地址" width="90px" align="center"/>
                        <el-table-column prop="addressLength" label="长度(字节)" width="56px" align="center"/>
                        <el-table-column prop="registerType" label="类型" width="90px" align="center" :formatter="registerTypeFormat"/>
                        <el-table-column prop="endian" label="大小端" width="60px" align="center" :formatter="endianFormat"/>
                        <el-table-column prop="analysisMode" label="数据类型" width="75px" align="center" :formatter="analysisModeFormat"/>
                        <el-table-column prop="factor" label="倍率/系数" width="90px" align="center"/>
                        <el-table-column prop="unit" label="单位" width="50px" align="center"/>
                        <el-table-column prop="autoAnalysis" label="是否解析" width="50px" align="center" :formatter="yesNoFormat"/>
                        <el-table-column prop="groupName" label="组名" align="center"/>
                        <el-table-column prop="groupOrder" label="组中序号" width="50px" align="center"/>
                    </el-table>
                </el-collapse-item>
                <el-collapse-item title="报文" name="message">
                    <el-form label-position="right" label-width="110px" disabled>
                        <el-row class="lastRow">
                            <el-col :span="8">
                                <el-form-item label="指令报文：">
                                    <label>{{detail.aduContent}}</label>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </el-card>
        <ModbusCommandOperation ref="modbusCommandOperationPage" @queryDetail="initPage" @refreshParentSettingTree="refreshParentSettingTree"></ModbusCommandOperation>
    </div>
</template>

<script>
import NavBreadcrumb from '../../components/NavBreadcrumb.vue';
import ModbusCommandOperation from "../../views/modbus/modbus-command-operation";
import message from "@/utils/message";
import { queryCommandInfoDetail, deleteCommandInfo } from "../../api/modbus/modbusCommand";

export default {

    name: "modbus-command-detail",
    components: {NavBreadcrumb, ModbusCommandOperation},
    props: {

        commandId: {

            type: Number,
            default: 0,
        },
        title: {

            type: String,
            default: '',
        }
    },
    watch:{

        commandId(news, olds){

            this.initPage();
        }
    },
    data() {

        return {

            loading: false,
            activeName: ['baseInfo', 'timerInfo', 'message'],
            detail: {

                commandCode: '',
                commandName: '',
                commandType: '',
                verification: '',
                verifyEndian: '',
                enableTimer: '',
                cron: '',
                autoSave: '',
                aduContent: '',
                remark: '',
                registerInfoList: [],
                pointInfoId: ''
            },

            commandTypeSelect: [
                {
                    value: 'READ',
                    label: '读'
                },
                {
                    value: 'WRITE',
                    label: '写'
                },
            ],
            yesNoSelect: [
                {
                    value: 1,
                    label: '是'
                },
                {
                    value: 0,
                    label: '否'
                },
            ],
            verificationSelect: [
                {
                    value: 1,
                    label: 'CRC16-MODBUS'
                },
            ],
            verifyEndianSelect: [
                {
                    value: 'LITTLE_ENDIAN',
                    label: '小端'
                },
                {
                    value: 'BIG_ENDIAN',
                    label: '大端'
                },
            ],
            endianSelect: [
                {
                    value: 'LITTLE_ENDIAN',
                    label: '小端'
                },
                {
                    value: 'BIG_ENDIAN',
                    label: '大端'
                },
            ],
        };
    },
    methods: {

        openEdit() {

            this.$refs.modbusCommandOperationPage.editOpenInitPage(this.commandId, this.detail.pointInfoId);
        },
        initPage() {

            this.loading = true;
            let param = {

                id: this.commandId,
                showPointAllRegisterInfo: false,
            }
            queryCommandInfoDetail(param).then((res) => {

                if (res.code === 200) {

                    this.detail = res.data;
                }
                this.loading = false;
            }).catch(err => {

                console.error(err);
                this.loading = false;
            });
        },
        refreshParentSettingTree() {

            this.$emit('refreshParentTree');
        },
        delCommand() {

            message.confirm("是否要删除该条指令信息？", false, "warning", ()=>{

                deleteCommandInfo({id: this.commandId}).then((res) => {

                    if (res.code === 200) {

                        message.success('删除成功');
                        this.$emit('refreshParentTree');
                        this.$emit('setParentNode');
                    } else {

                        message.error(res.msg);
                    }
                }).catch(err=> console.log(err));
            })
        },
        rwModelFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '读';
            } else if (cellValue === 2) {

                return '读/写';
            } else if (cellValue === 3) {

                return '写';
            } else {

                return '未知';
            }
        },
        registerTypeFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '保持寄存器';
            } else if (cellValue === 2) {

                return '输入寄存器';
            } else if (cellValue === 3) {

                return '线圈';
            } else if (cellValue === 4) {

                return '离线输入';
            } else {

                return '未知';
            }
        },
        endianFormat(row, column, cellValue) {

            if (cellValue === 'BIG_ENDIAN') {

                return '大端';
            } else if (cellValue === 'LITTLE_ENDIAN') {

                return '小端';
            }else {

                return '未知';
            }
        },
        analysisModeFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '有符号';
            } else if (cellValue === 2) {

                return '无符号';
            } else if (cellValue === 3) {

                return 'BIT位';
            } else if (cellValue === 4) {

                return 'ASCII';
            } else {

                return '未知';
            }
        },
        yesNoFormat(row, column, cellValue) {

            if (cellValue === 1) {

                return '是';
            } else if (cellValue === 0) {

                return '否';
            } else {

                return '未知';
            }
        },
    },
    mounted() {

        this.initPage();
    },
}
</script>

<style scoped>

.title {

    font-weight: bolder;
    font-size: larger;
}

.lastRow {

    margin-bottom: -20px;
}

.card {

    margin-top: 20px;
}

.optItem {

    width: 80%;
}

.textArea {

    width: 95%;
}
</style>
